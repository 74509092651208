import { createStore } from 'vuex'

export default createStore({
  state() {
    return {
      sessionId: "",
      chargingPointInfo: {},
      chargingPointId: "",
      tariffSetting: {},
      notificationPhone: "",
      isLoading: true,
      discountMessage: ""
    }
  },
  getters: {
  },
  mutations: {
    setSessionId(state, newSessionId){
      state.sessionId = newSessionId
    },

    setChargingPointInfo(state, infoObj){
      state.chargingPointInfo = infoObj
    },
    
    setNotificationPhone(state, newPhone){
      state.notificationPhone = newPhone
    },

    setChargingPointId(state, newChargingPointId){
      state.chargingPointId = newChargingPointId
    },

    setTariffSetting(state, newTariffSetting){
      state.tariffSetting = newTariffSetting
    },

    setDiscountMessage(state, msg){
      state.discountMessage = msg
    },

    setIsLoading(state, newLoadingStatus){
      state.isLoading = newLoadingStatus
    }
  },
  actions: {
  },
  modules: {
  }
})
