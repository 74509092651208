import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axiosPlugin from './plugins/axios'
import LoadScript from "vue-plugin-load-script"
import 'vue-loading-overlay/dist/css/index.css'
import moment from 'moment'
const app = createApp(App)

app.use(store)
app.use(router)
router.app = app
app.use(LoadScript)
app.use(axiosPlugin,{})
app.config.globalProperties.$checkChargingPointStatus = async function(chargingPointId, openMenu = "", from = "", ticket = ""){
    if(chargingPointId !== '') {
        return this.$axios.get(
            `ChargePoint/${chargingPointId}?openMenu=${openMenu}&from=${from}&ticket=${ticket}`,
            {
                headers: {
                    'access-key': ''
                }
            }
        )
    }

    return this.$axios.get(`ChargePoint`, {
        headers: {
            'access-key': store.state.sessionId
        }
    })
}
app.config.globalProperties.$moment = moment
app.config.globalProperties.$_HARDWARE_STATUS = {
    "available":process.env.VUE_APP_HARDWARE_STATUS_available,
    "charging":process.env.VUE_APP_HARDWARE_STATUS_charging,
    "suspendedEV":process.env.VUE_APP_HARDWARE_STATUS_suspendedEV,
    "suspendedEVSE":process.env.VUE_APP_HARDWARE_STATUS_suspendedEVSE,
    "finished":process.env.VUE_APP_HARDWARE_STATUS_finished,
    "finishing":process.env.VUE_APP_HARDWARE_STATUS_finishing,
    "reserved":process.env.VUE_APP_HARDWARE_STATUS_reserved,
    "unavailable":process.env.VUE_APP_HARDWARE_STATUS_unavailable,
    "faulted":process.env.VUE_APP_HARDWARE_STATUS_faulted
}
console.log(app.config.globalProperties.$_HARDWARE_STATUS)
app.mount('#app')