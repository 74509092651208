<template>
  
  <div class="home">
    <logoBackground />
    <!-- 主要內容 -->
    <div class="d-flex justify-content-center mainContentBox mb-3">
      <div class="d-flex align-items-center mainContent flex-wrap justify-content-start">
        <div class="col-3">
          <div class="imgContainer mx-auto">
            <img
              v-if="chargingMachinePreview == ''"
              src="../assets/img/kW.png"
              class="img-fluid rounded-start"
              alt="Charging preview"
            />
            <img
              v-else
              :src="chargingMachinePreview"
              class="img-fluid rounded-start"
              alt="Charging preview"
            />
          </div>
        </div>
        <div class="col-9">
          <div class="">
            <h5 class="cardTitle d-flex" v-if="chargingPointStatus!=='notExist' ">
              <div>{{ stationName }}</div>
              <div class="ps-2" v-if="(maxKW !== 0)">{{ maxKW }}kw</div>
            </h5>
            <h5 class="cardTitle d-flex" v-else>
              <div class="alert-text">查無此充電站</div>
            </h5>
            <div class="cardSubTitle" v-if="chargingPointStatus==='notExist' ">
              <div
                class="d-flex justify-content-flex-start align-items-center"
              >
                <div class="">槍頭編號</div>
                :
                <div class="cardUserSubTitle ps-2">-</div>
                <div class="cardUserSubTitle opacity-75"></div>
              </div>
              <div class="d-flex">
                <div class="">充電費率</div>
                :
                <div class="cardUserSubTitle ps-2">-</div>
                <div class="cardUserSubTitle opacity-75"></div>
              </div>
            </div>
            <div class="cardSubTitle" v-else>
              <div
                class="d-flex justify-content-flex-start align-items-center"
              >
                <div class="">槍頭編號:</div>
                <div class="cardUserSubTitle ps-2">{{ fileNumber }}</div>
                <div class="cardUserSubTitle opacity-75" v-if="connector !== ''">({{ connector }})</div>
              </div>
              <div class="d-flex flex-wrap">
                <div class="">充電費率:</div>
                <div class="cardUserSubTitle pricePerKwhText w-100 text-start" v-if="pricePerKwh !== 0">{{ pricePerKwh }}</div>
                <div class="cardUserSubTitle ps-2" v-else>載入中</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12" v-if="memo">
          <p class="memo-line mx-3 mt-2 mb-0 pt-2" v-html="memo"></p>
        </div>
      </div>
    </div>
    <!-- 標語 -->
    <div class="suTitle" v-if="chargingPointStatus !== 'notExist' && (status!=='disabled' && status!=='out of order') && !isNaN(chargePoint) && sessionId!=='' && (hardwareStatus==='available' || hardwareStatus==='preparing')">完成付款方式設定後，即可插槍並開始充電</div>
    <div class="suTitle" v-else-if="chargingPointStatus === 'notExist'">查無此充電站，請重新再試</div>
    <!-- btn -->
    <div v-if="status==='disable' || status==='out of order'">
      <button type="button" class="btn btn-disabled">無法提供服務</button>
    </div>
    <router-link :to="{ name: 'Pay', path: 'pay' }" v-if="(status!=='disabled' && status!=='out of order') && !isNaN(chargePoint) && sessionId!=='' && (hardwareStatus==='available' || hardwareStatus==='preparing')">
      <BtnView />
    </router-link>
    <div v-else-if="hardwareStatus==='charging' ">
      <button type="button" class="btn btn-disabled">充電中</button>
    </div>
    <div v-else-if="hardwareStatus==='loading' ">
      <button type="button" class="btn btn-disabled">載入中</button>
    </div>
    <div v-else class="suTitle py-0">無法充電，請確認槍頭已拔出或充電樁已連網</div>
  </div>
</template>

<script>
import logoBackground from "@/components/logoBackground.vue";
import BtnView from "@/components/BtnView.vue";

export default {
  name: "HomeView",
  components: {
    logoBackground,
    BtnView
  },
  data(){
    return {
      "sessionId":"",
      "chargePoint":0,
      "openMenu":"",
      "from":"",
      "ticket":"",
      "stationName":"載入中",
      "chargingMachinePreview":"",
      "status":"",
      "hardwareStatus":"loading",
      "connector":"",
      "fileNumber":"載入中",
      "pricePerKwh":0,
      "maxKW":0,
      "memo":"",
      "chargingPointStatus":"waiting"
    }
  },
  mounted() {
    // 車廠合作專案須接以下參數
    // openMenu=true&from=mbpass&ticket=Moq5ijPaxpSNb
    this.chargePoint = parseInt(this.$route.params.chargePoint)
    
    if(typeof this.$route.query.openMenu!=='undefined'){
      this.openMenu = (this.$route.query.openMenu).trim()
    }
    if(typeof this.$route.query.from!=='undefined'){
      this.from = (this.$route.query.from).trim()
    }
    if(typeof this.$route.query.ticket!=='undefined'){
      this.ticket = (this.$route.query.ticket).trim()
    }

    if(isNaN(this.chargePoint)){
      this.$store.state.isLoading = false
      return false
    }
    this.$store.state.isLoading = true
    this.$axios
    .get(
      `ChargePoint/${this.chargePoint}?initial=1&openMenu=${this.openMenu}&from=${this.from}&ticket=${this.ticket}`,
      {
        headers: {
          'access-key': ''
        }
      }
    )
    .then( response =>{
      this.stationName = response.data.location.name
      if(response.data.location.image){
        this.chargingMachinePreview = response.data.location.image
      }
      this.fileNumber = response.data.physicalReference
      this.memo = response.data.location.detailed_description['zh-TW']
      this.connector = response.data.chargePoint.evse.connector
      this.hardwareStatus = response.data.chargePoint.evse.hardwareStatus
      if(response.data.tariff){
        this.pricePerKwh = response.data.tariff.description
      }
      this.maxKW = (response.data.chargePoint.evse.maxPower / 1000).toFixed(1)
      this.$store.state.sessionId = response.data.sessionId
      this.$store.state.chargingPointId = this.chargePoint
      // keep tariff information
      this.$store.state.tariffSetting = response.data.tariff
      this.sessionId = response.data.sessionId

      this.status = response.data.chargePoint.evse.status
      this.$store.state.isLoading = false
    })
    .catch(error=>{
      if(error.response.status === 500){
        if(error.response.data.message === '查無此槍'){
          this.chargingPointStatus = "notExist"
        }
      }
      this.$store.state.isLoading = false
      console.error(error)
    })
  }
};
</script>
<style>
.selfWidth {
  padding: 34px;
}
.suTitle {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145.2%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #fefefe;
  padding-top: 52px;
  padding-bottom: 20px;
}
.mainContent {
  width: 100%;
  color: #fefefe;
  font-weight: 400;
  font-size: 14px;
  line-height: 145.2%;
  letter-spacing: 0.05em;
  background: #2a2a2a;
  border: 0.5px solid #01b2c3;
  border-radius: 8px;
  padding: 8px 0;
}
.mainContentBox {
  margin: auto;
  max-width:85%
}
.imgContainer {
  width: 74px;
  height: 108px;
}
.cardTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 145.2%;
  margin-bottom: 12px;
}
.cardSubTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 145.2%;
}
.cardUserSubTitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 145.2%;
}

.memo-line{border-top:.5px solid #01B2C3;text-align:left !important;}
.alert-text{color:#FF6A6A}
button.btn.btn-disabled{
  height: 42px;
  width: 134px;
  border-radius: 30px;
  padding: 0px 3px 0px 3px;
  background: linear-gradient(90.38deg, #c6c6c6 0.33%, #f0f0f0 105.25%);
  text-align: center;
  line-height: 200%;
  color: #3b3b3b;
  text-decoration: none;
}
</style>
