<template>
  <div class="d-flex bg-light align-items-center justify-content-between px-2 advertisement-bar" v-if="showingAdvertisement">
    <div class="p-2 bg-light">
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="this.showingAdvertisement = false"
      >
        <path
          d="M1 1L11 11"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M1 11L11 1"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    </div>
    <div class="p-2 bg-light">
      <div class="d-flex align-items-center justify-content-center">
        <div class="">
          <img class="" src="../assets/img/logo.png" alt="logo" />
        </div>

        <div class="downloadSub text-start flex-grow-1 ms-3 flex-fill">
          即刻下載 EVOASIS APP 享受更好的充電體驗
        </div>
      </div>
    </div>
    <!-- 需要再調整 -->
    <div class="downloadApp">
      <a href="https://bit.ly/3EgbtD3" target="_blank" class="btn btn-sm">下載App</a>
    </div>
    <!-- 需要再調整 -->
  </div>
</template>
<script>
export default {
  name: "FrameView",
  data(){
    return {
      showingAdvertisement: true
    }
  }
};
</script>

<style scoped lang="scss">
.downloadApp {
  width: 73px;
  height: 30px;
  left: 286px;
  top: 19px;
  background: #32a8ba;
  border-radius: 4px;
  > a {
    font-size: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #ffffff;
  }
}
.downloadSub {
  font-weight: 400;
  font-size: 12px;
  width: 178px;
  height: 35px;
}
</style>
