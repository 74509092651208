<template>
  <div class="mx-auto p-5">
    <img src="../assets/img/Frame.png" class="img-fluid" alt="Frame" />
  </div>
</template>
<script>
export default {
  name: "logoBackground",
};
</script>

<style scoped lang="scss"></style>
